







































































































































































































































import { Route } from 'vue-router'
import { documentsModule, stateModule } from '@/store'
import { canPerformAction } from '@/helpers/canPerformAction'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import QuickList from '@/components/list/QuickList.vue'
import SwitchInput from '@/components/inputs/Switch.vue'
import DocumentModal from '@/components/modals/Document.vue'
import DocumentEditor from '@/components/document/Editor.vue'
import ProposalSelect from  '@/views/dashboard/projects/detail/process/Select.vue'
import CreateProposalDocumentViewer from '@/components/proposal/modals/CreateProposalDocumentViewer.vue'

import { ComponentValue } from '@/models/widgets/ComponentValue'
import { ProcessResource } from '@/models/process/ProcessResource'
import { ProjectResource } from '@/models/projects/ProjectResource'
import { DocumentResource } from '@/models/documents/DocumentResource'
import { ComponentResource } from '@/models/widgets/ComponentResource'
import { ProposalResource } from '@/models/proposals/ProposalResource'
import {ProposalCollectionLightResource} from '@/models/proposals/ProposalCollectionLightResource'

import { ProposalCreateRequest } from '@/requests/proposals/ProposalCreateRequest'
// import { CommentCreateRequest } from '@/requests/CommentCreateRequest'
import { DocumentPatchRequest } from '@/requests/documents/DocumentPatchRequest'
import ConnectChild from '@/components/icons/connectChild.vue'

import { CorlyticsService } from '@/services/corlytics'
import Metatags from '@/components/metatags/Metatags.vue'
import TranslatedTitle from '@/components/metatags/TranslatedTitle.vue'

const isDocumentAlreadyAvailable = (
  to: Route,
  document: DetailResponse<DocumentResource> | null,
) => {
  return (
    (to.params.document_id ? to.params.document_id.toString() : null) ===
    (document ? document.data.id.toString() : null)
  )
}

// Clone route because there is an bug when using Route inside a Watcher the decorator throws an error
type ClonedRoute = Route

@Component({
  components: {
    ConnectChild,
    QuickList,
    SwitchInput,
    DocumentModal,
    DocumentEditor,
    ProposalSelect,
    CreateProposalDocumentViewer,
    Metatags,
    TranslatedTitle
  },
})
export default class ProjectsDetailDocumentIndex extends Vue {
  @Prop()
  private readonly project!: ProjectResource

  private selectedProposals: ProposalCollectionLightResource[] = []

  private loading: boolean = false
  private fontBigger: boolean = false
  private showChanges: boolean = false
  private showIndex: boolean = true
  private diffDocument: DetailResponse<DocumentResource> | null = null
  private markerEnabled: boolean = true
  private markedData: ComponentValue | null = null
  private document: DetailResponse<DocumentResource> | null = null
  private documentPickerOpen: boolean = false

  private isCreateProposalModalOpen: boolean = false

  private metaTags: any = {}

  private corlyticsService: CorlyticsService = new CorlyticsService()

  private get process(): ProcessResource | undefined {
    return this.project.documentProcess
  }

  private get nextProcess(): ProcessResource | undefined {
    return this.project.getProcessByOrder((this.process?.order ?? 0) + 1)
  }

  private get isNextProcessMarking(): boolean {
    return this.nextProcess?.process_type === 'marking'
  }

  private get metaTagsExist(): boolean {
    if (!this.metaTags) return false
    const tagTypes = ['reg_categories', 'service_lines', 'themes', 'client_taxonomies'];
    let tagsNum = 0

    tagTypes.forEach((type: string) => {
      if (this.metaTags[type]?.length) tagsNum += this.metaTags[type].length
    })
    return tagsNum > 0
  }

  private get showSideProposal(): boolean {
    return ['projects-detail-document-proposal-detail', 'projects-detail-document-detail'].includes(this.$route.name ?? '')
  }

  private get showSideMultiSelect(): boolean {
    return this.selectedProposals.length > 0
  }

  private get showSide(): boolean {
    return this.showSideProposal || this.showSideMultiSelect
  }


  private get markingId(): number | null {
    return documentsModule.markingId
  }

  private get htmlViewerComponent(): ComponentResource | null {
    if (this.nextProcess && this.nextProcess.widgets) {
      const widget = this.nextProcess.widgets.find(
        (item) => item.name === 'HtmlViewer'
      )
      return widget ? widget.components[0] : null
    }
    return null
  }

  private get hasChanges(): boolean {
    if (this.document && this.document.data) {
      return this.document.data.has_changes
    }
    return false
  }

  private get viewChangesTooltip(): string {
    if (this.hasChanges) {
      return `<strong>View changes</strong>View changes in this document relative to the previous version.`
    }

    return `No changes can be shown, as there is no earlier version available.`
  }

  private get canMarkText(): boolean {
    return this.$route.params.document_id && this.document && this.process
      ? canPerformAction(this.process.permissions, 'can_create_proposal')
      : false
  }

  private get isOnDocument(): boolean {
    return this.$route && this.$route.name
      ? this.$route.name === 'projects-detail-document' ||
          this.$route.name === 'projects-detail-document-detail'
      : false
  }

  @Watch('$route')
  private async onRouteChange(val: ClonedRoute, from: ClonedRoute): Promise<void> {
    // Close changes
    if (this.showChanges) {
      this.showChanges = false
    }

    if (
      (!isDocumentAlreadyAvailable(val, this.document) &&
        val.name === 'projects-detail-document') ||
      (from.name === 'projects-detail-document-proposals' &&
        val.name === 'projects-detail-document') ||
      from.name === 'projects-detail-document-proposal-detail'
    ) {
      await documentsModule.resetMarking()
      await this.getDocument()
      this.scrollTop()
      this.resetMarkedData()
    } else {
      await documentsModule.resetMarking()
      this.resetMarkedData()
    }
  }

  @Watch('showChanges')
  private onShowChanges(val: boolean): void {
    if (
      val &&
      this.$route.name !== 'projects-detail-document' &&
      this.document
    ) {
      this.$router.push({
        name: 'projects-detail-document',
        params: {
          project_id: this.project.id.toString(),
          document_id: this.document.data.id.toString(),
        },
      })

      this.$nextTick(() => (this.showChanges = true))
    }
  }

  private deselectProposal(proposalId: number) {
    const PROPOSAL_INDEX = this.selectedProposals.findIndex((p) => p.id === proposalId)
    if (PROPOSAL_INDEX === -1) return
    this.selectedProposals.splice(PROPOSAL_INDEX, 1)
  }

  private deselectAll() {
    this.selectedProposals = []
  }

  private scrollTop(): void {
    const wrapperDiv = document.getElementById('documentContainer')
    if (wrapperDiv) wrapperDiv.scrollTop = 0
  }

  private async mounted(): Promise<void> {
    await this.getDocument()
    this.getMetadata()
  }

  private beforeDestroy() {
    this.project.resetDocument()
  }

  private async getMetadata() {
    try {
      const meta = await this.corlyticsService.getMetadata(this.project.id)
      this.metaTags = meta[+this.$route.params.document_id]
    }
    catch(e) {}
  }

  private async getDocument(): Promise<void> {
    stateModule.setLoading(true)
    try {
      this.document = await this.project.getDocument(+this.$route.params.document_id)
      const contentData = await this.corlyticsService.getPublicationContent(this.project.id, this.document.data.id)
      this.document.data.document = contentData.document;

      this.$nextTick(() => {
        this.setClickHandlers()
      })
    } catch (e) {
      // Go back if errored
      // this.$router.go(-1)
    } finally {
      stateModule.setLoading(false)
    }
  }

  private setClickHandlers(): void {
    this.$nextTick(() => {
      const overlappingIds: string[] = []

      const proposalsSpans = document.getElementsByClassName(
        'proposal',
      ) as HTMLCollectionOf<HTMLElement>
      for (const span of proposalsSpans) {
        span.addEventListener('click', this.goToRequirementRoute)
        // Disabled due to performance impact
        // span.onmouseover = () => {
        //   const proposalId = span.dataset.proposalId
        //   const proposals = document.querySelectorAll(`span[data-proposal-id="${proposalId}"]`)
        //   for (const proposalSpan of proposals) {
        //     proposalSpan.classList.add('hover')
        //   }
        // }
        // span.onmouseout = () => {
        //   for (const proposalSpan of proposalsSpans) {
        //     proposalSpan.classList.remove('hover')
        //   }
        // }

        // Find overlap ID
        if (span.hasAttribute('data-overlapping-id')) {
          const overlappingId = span.getAttribute('data-overlapping-id')

          if (overlappingId && overlappingIds.indexOf(overlappingId) === -1) {
            overlappingIds.push(overlappingId)
          }
        }
      }

      if (this.$route.name === 'projects-detail-document-detail') {
        this.modifyActiveDomProposalStatus(
          true,
          parseInt(this.$route.params.proposal_id, 10),
        )
      }

      const referenceSpans = document.getElementsByClassName(
        'reference',
      ) as HTMLCollectionOf<HTMLElement>
      for (const span of referenceSpans) {
        span.addEventListener('click', this.goToReferenceRoute)
      }

      // Remove overlapping span requirements
      if (overlappingIds.length > 0) {
        for (const id of overlappingIds) {
          const overlappingSpans = document.querySelectorAll(
            `span[data-proposal-id="${id}"]`,
          )

          for (const span of overlappingSpans) {
            span.removeAttribute('data-inherited')
            span.removeAttribute('data-proposal-id')
            span.classList.remove('identified')
            span.classList.remove('proposal')
            span.classList.remove('nlp-proposal')
            span.removeEventListener('click', this.goToRequirementRoute)
          }
        }
      }
    })
  }

  private modifyActiveDomProposalStatus(
    activate: boolean,
    proposalId?: number | undefined,
  ): void {
    const wrapperDiv = document.getElementById('documentContainer')

    if (activate) {
      if (wrapperDiv) wrapperDiv.classList.add('inactive')
      const proposalSpans = document.querySelectorAll(
        `span[data-proposal-id="${proposalId}"]`,
      )
      const activeSpans = document.querySelectorAll(`span.active`)

      for (const span of activeSpans) {
        span.classList.remove('active')
      }

      for (const span of proposalSpans) {
        span.classList.add('active')
      }
    } else {
      if (wrapperDiv) wrapperDiv.classList.remove('inactive')
      const activeSpans = document.querySelectorAll(`span.active`)

      for (const span of activeSpans) {
        span.classList.remove('active')
      }
    }
  }

  private async goToRequirementRoute(e: Event): Promise<void> {
    e.stopPropagation()
    e.preventDefault()

    if (!this.document) {
      return
    }

    const span = e.target as HTMLSpanElement
    const proposalNodeId = span.attributes.getNamedItem('data-proposal-id')
    if (proposalNodeId) {
      // Open side panel if it wasn't open
      await this.$router.push({
        name: 'projects-detail-document-detail',
        params: {
          project_id: this.project.id.toString(),
          document_id: this.document.data.id.toString(),
          proposal_id: proposalNodeId.value,
        },
        query: {
          inherited:
            span.hasAttribute('data-inherited') &&
            span.getAttribute('data-inherited') === 'true'
              ? 'true'
              : '',
        },
      })
    }
  }

  private async goToReferenceRoute(e: Event): Promise<void> {
    e.stopPropagation()
    e.preventDefault()

    if (!this.document) {
      return
    }

    const span = e.target as HTMLSpanElement
    const referenceIdNode = span.attributes.getNamedItem('data-reference-id')
    if (referenceIdNode) {
      await this.$router.push({
        name: `projects-detail-document-reference`,
        params: {
          project_id: this.project.id.toString(),
          document_id: this.document.data.id.toString(),
          referenceId: referenceIdNode.value,
        },
      })
    }
  }

  private resetMarkedData(): void {
    this.markedData = null

    const S = window.getSelection()
    if (S) {
      S.removeAllRanges()
    }
  }

  private updateMarkedData(data: {
    data: string
    start_id: number
    end_id: number
  }): void {
    if (this.htmlViewerComponent) {
      this.markedData = new ComponentValue({
        data: data.data,
        start_id: data.start_id,
        end_id: data.end_id,
        component: this.htmlViewerComponent,
      })
    }
  }

  private async submitMarkedData(): Promise<void> {
    stateModule.setLoading(true)
    if (!this.nextProcess || !this.markedData || !this.document) {
      return
    }
    const markedData: ComponentValue = { ...this.markedData }

    const body: ProposalCreateRequest = {
      proposal_name: '',
      values: [markedData],
      document_id: this.document ? this.document.data.id : undefined,
      notify_user: []
    }

    try {
      this.loading = true
      const { data } = await this.nextProcess.createProposal(body)
      await this.$router.push({
        name: 'projects-detail-document-detail',
        params: {
          project_id: this.project.id.toString(),
          document_id: this.document.data.id.toString(),
          proposal_id: data.id.toString()
        }
      })
      this.setDomRequirement(data, markedData)
      this.resetMarkedData()
    } finally {
      stateModule.setLoading(false)
      this.loading = false
    }
  }

  private setDomRequirement(
    data: ProposalResource,
    markedData: ComponentValue,
  ): void {
    const overlappingIds: string[] = []

    if (markedData && markedData.end_id && markedData.start_id) {
      for (let i = markedData.start_id; i <= markedData.end_id; i++) {
        const span = document.querySelector(`span[data-count="${i}"]`)

        // Find overlapping proposal
        if (span && span.classList.contains('nlp-proposal')) {
          const overlappingId = span.getAttribute('data-proposal-id')

          if (overlappingId && overlappingIds.indexOf(overlappingId) === -1) {
            overlappingIds.push(overlappingId)
          }
        }

        if (span) {
          span.classList.add('proposal')
          span.classList.add(data.status.value.toString())
          span.setAttribute('data-proposal-id', data.id.toString())
          span.addEventListener('click', this.goToRequirementRoute)
          this.modifyActiveDomProposalStatus(true, data.id)
        }
      }
    }

    // Remove old classes / click events from overlaps
    if (overlappingIds.length > 0) {
      for (const id of overlappingIds) {
        const overlappingSpans = document.querySelectorAll(
          `span[data-proposal-id="${id}"]`,
        )

        for (const span of overlappingSpans) {
          span.removeAttribute('data-inherited')
          span.removeAttribute('data-proposal-id')
          span.classList.remove('identified')
          span.classList.remove('proposal')
          span.classList.remove('nlp-proposal')
          span.removeEventListener('click', this.goToRequirementRoute)
        }
      }
    }
  }

  private async patch(body: DocumentPatchRequest): Promise<void> {
    if (!this.document) {
      return
    }

    stateModule.setLoading(true)
    try {
      await this.document.data.patch(body)
    } finally {
      stateModule.setLoading(false)
    }
  }

  private async comment(body: any): Promise<void> {
    if (this.document) {
      stateModule.setLoading(true)

      try {
        await this.document.data.postComment({
          message: body.message,
          parent_id: body.parent_id,
        })
      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private async openChanges(): Promise<void> {
    if (!this.document) {
      return
    }

    // If we already opened the document
    // we dont need to get the document again to just open it.
    if (
      this.diffDocument &&
      this.document.data.id === this.diffDocument.data.id
    ) {
      this.showChanges = true
      return
    }

    // Toggle if already open
    if (this.showChanges) {
      this.showChanges = !this.showChanges
      return
    }

    stateModule.setLoading(true)
    try {
      this.diffDocument = await this.project.getDocumentDiff(
        this.document.data.id,
      )
      this.showChanges = true
    } finally {
      stateModule.setLoading(false)
    }
    this.showChanges = true
  }

  private toggleMarker(): void {
    if (this.markerEnabled) {
      this.resetMarkedData()
      this.markerEnabled = false
    } else {
      this.markerEnabled = true
    }
  }

  private closeChanges(): void {
    this.showChanges = false
  }

  private toggleIndex(): void {
    this.showIndex = !this.showIndex
  }

  private goToOverview(): void {
    if (this.project && this.document) {
      if (this.$route.name === 'projects-detail-document-proposal-detail') {
        this.$router.push({
          name: 'projects-detail-document-proposals',
          params: {
            project_id: this.project.id.toString(),
            document_id: this.document.data.id.toString(),
          },
        })
      } else {
        this.$router.push({
          name: 'projects-detail-document',
          params: {
            project_id: this.project.id.toString(),
            document_id: this.document.data.id.toString(),
          },
        })
      }
    }
  }

  private toggleFontSize(): void {
    this.fontBigger = !this.fontBigger
  }

  private downloadFile(): void {
    if (this.document) {
      window.open(this.document.data.link, '_blank')
    }
  }

  private closeDocumentPicker() {
    this.documentPickerOpen = false
  }
}
